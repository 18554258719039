:root {
  --mr-color-1: #F5F5F6;
  --mr-color-2: #3B3054;
  --mr-color-3: #94D2F1;
  --mr-color-4: #DB5655;
  --mr-color-5: #FBD669;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p, div, span {
  font-family: 'Roboto';
}


::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: none;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display:none;
}

.btn-mrz-primary, .ant-btn-primary, .btn-primary {
  box-shadow: none;
  background: #3b7779 !important;
  border-color: #3b7779 !important;
  box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px;
  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 10px;
  color: #FFFFFF;
}

.btn-mrz-default, .ant-btn-default, .btn-default {
  /* background: #FFB84F !important; */
  /* box-shadow: 0px 4px 14px rgb(217 217 217 / 25%); */
  box-shadow: none;
  background-color: #fffee5 !important;
  border: #fdb256;
  /* box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px; */
  font-weight: 500;

  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 10px;
  /* color: #FFFFFF; */
}

.btn-mrz-secondary, .ant-btn-secondary, .btn-secondary {
  box-shadow: none;
  background: #fffee5 !important;
  border-color: #fffee5 !important;
  /* box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px; */
  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 10px;
  color: #263238 !important;
}

.btn-mrz-warning, .ant-btn-warning, .btn-warning {
  box-shadow: none;
  background: #fdb256 !important;
  border-color: #fdb256 !important;
  /* box-shadow: rgb(217 217 217 / 25%) 0px 6px 17px; */
  border-radius: 7px;
  font-weight: 700;
  font-size: 15px;
  padding: 5px 10px;
  color: #FFFFFF !important;
}

.ant-modal-content {
  border-radius: 25px;
}

.stamp-container .accordion {
  padding-bottom: 40px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-editor ol, .ql-editor ul {
  padding-left: 0 !important;
}

.bg-gradient-danger {
  background: linear-gradient( 160deg,#db5655,#db5655,#db5655,#efc879) !important;
  /* background: linear-gradient(125deg,#f5365c,#f56036)!important; */
}

.full-screen-modal {
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: 100% !important;
  /* max-height: 600px; */
}
.full-screen-modal .ant-modal-content {
  height: 100%;
  border-radius: 0;
}

.login-antd-modal .ant-modal-content {
  background-color: #F5F5F6;
}

.rewardz-modal .ant-modal-body {
  padding: 0 0 14px 0;
}

.rewardz-modal .ant-modal-close-x svg {
  background: white;
  border-radius: 50%;
}

.ant-modal {
  max-width: 360px;
}

.ant-modal-content {
  border-radius: 25px;
}

.ant-modal-content .close-icon {
  font-size: 30px;
  color: #DB5655;
}

.wallet-modal {
  color: var(--mr-color-2);
}

.wallet-modal-header {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.wallet-modal-header img {
  margin-right: 10px;
}

.wallet-modal-header span {
  font-size: 24px;
  font-weight: 500;
}

.wallet-modal-body {
  text-align: center;
}

.wallet-modal-body .price {
  font-size: 48px;
  font-weight: bold;
}

.wallet-modal-body p {
  font-size: 18px;
  font-weight: 500;
}

.wallet-modal-body .current {
  font-size: 20px;
  font-weight: bold;
  color: #db5655;
}

.wallet-modal-body .insufficient-balance {
  color: #db5655; 
}

.wallet-modal-body button.top-up {
  width: 100%;
  padding: 14px;
  background: #3B7779;
  box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
  border-radius: 7px;

  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  color: #FFFFFF;
}

.wallet-modal-body button.outline {
  width: 100%;
  padding: 14px;
  background: #FFB84F;
  box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
  border-radius: 7px;

  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  color: #FFFFFF;
}

.wallet-modal-body .option{
  color: #BEBCC5;
  font-size: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.stipe-button {
  background: #635BFF;
  border-radius:3px;
  color: white;
  border: 0;
  padding: 10px 16px;
  margin-top: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}

.Demo__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.Demo__container div {
  display: inline-block;
}

.Demo__some-network {
  /* margin: 0 13px;
  padding: 6px 0; */
  width: 25%;
}

.Demo__some p {
  font-weight: 400;
  font-size: 13px;
  color: #000000;
}

.liked svg {
  color: #0084ff !important;
}

.disliked svg {
  color: #dc3545 !important;
}

.ant-modal-header {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.circularCrop .cropper-view-box {
	border-radius: 50%;
}
.circularCrop .cropper-face {
	background-color:inherit !important;
}

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-item {
  background-color: transparent;
}

.item:hover {
  transform: scale(1.5);
}